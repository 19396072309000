<template>
  <div class="container-fluid top-menos">
    <loading-overlay
      :active="loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.HoldList')"
      color="dark"
      size="xl"
      class="modal-extended"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedListHold"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    HoldItem: [],
    modalActive: false,
    loading: false,
  };
}

function closeModal() {
  this.HoldItem = [];
  this.$emit('closeModal');
}

function computedListHold() {
  return this.HoldItem.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
    { label: this.$t('label.hold'), key: 'VesselHoldName', _classes: 'text-center', _style: 'width:3%;',},
    { label: this.$t('label.TotalCertification')+this.$t('label.metricTonnageAcronym'), key: 'CertificateWeigth', _classes: 'text-center', _style:'width:8%' },
    { label: this.$t('label.baleCapacity')+this.$t('label.metricTonnageAcronym'), key: 'Bale', _classes: 'text-center', _style:'width:8%' },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:3%' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'text-center', _style:'width:3%' },
  ];
}


function mountValues(Holds) {
  this.HoldItem = Holds.DraftHoldJson;
  this.HoldItem.map((item) => {
    item.CertificateWeigth = formatNumber(item.CertificateWeigth);
    item.Bale = formatNumber(item.Bale);
  });
  this.loading = false;
}

function formatNumber(number) {
  if (number == "") return "0,00";
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}

export default {
  name: 'modal-hold-information',
  props: { modal: Boolean, HoldItems: Object },
  data,
  mixins: [
    GeneralMixin, 
  ],
  directives: UpperCase,
  methods: {
    formatNumber,
    closeModal,
    mountValues,
  },
  computed: {
    computedListHold,
    fields,
  },
  watch: {
    HoldItems: function(val) {
      if (Object.keys(val).length !== 0) {
        this.loading = true;
        this.modalActive = true;
        this.mountValues(val);
      }else{
        this.modalActive = false;
      }
    },
  },
  
};
</script>