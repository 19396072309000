var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid top-menos"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('CCol',{staticClass:"text-right mt-4",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.DraftInspection'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.DraftInspection'),\n          placement: 'top-end'\n        }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){_vm.generar=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedListDraft,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.edit')+' '+_vm.$t('label.DraftInspection'),
                      placement: 'top-end'
                  }),expression:"{\n                      content: $t('label.edit')+' '+$t('label.DraftInspection'),\n                      placement: 'top-end'\n                  }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.HoldList'),
                      placement: 'top-end'
                  }),expression:"{\n                      content: $t('label.HoldList'),\n                      placement: 'top-end'\n                  }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.toggleHold(item)}}},[_c('CIcon',{attrs:{"name":"hold"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.generateReport'),
                      placement: 'top-end'
                  }),expression:"{\n                      content: $t('label.generateReport'),\n                      placement: 'top-end'\n                  }"}],staticClass:"mr-1",attrs:{"square":"","color":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.printPdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}}])})],1)],1),_c('modal-hold-information',{attrs:{"HoldItems":_vm.HoldItems},on:{"closeModal":function($event){return _vm.CloseHoldModal()}}}),_c('draft-inspection',{attrs:{"generar":_vm.generar,"EditDraftItems":_vm.EditDraftItems,"isEdit":_vm.isEdit},on:{"update:generar":function($event){_vm.generar=$event},"close-Modal":_vm.CloseModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }